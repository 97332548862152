<template>
  <div>
    <b-card no-body>
      <startup-card :item-data="startup" />
    </b-card>
    <b-card title="Raporlar">
      <b-row>
        <b-col
          v-for="item in dataList"
          :key="item.id"
          cols="12"
          md="4"
          class="mb-2"
        >
          <div class="border rounded p-2 text-center">
            <div class="font-weight-bold text-primary">
              {{ item.startup_report }}
            </div>
            <div
              class="d-flex align-items-center justify-content-center"
              :class="item.status === '1'? 'text-success' : 'text-muted'"
            >
              <FeatherIcon
                :icon="item.status === '1'? 'CheckCircleIcon' : 'ClockIcon'"
              />
              {{ item.status === '1'? 'Tamamlandı' : 'Beklemede' }}
            </div>
            <div class="mt-1">
              <b-button
                variant="outline-primary"
                size="sm"
                :to="item.url + $route.params.id"
                :disabled="item.status !== '1'"
              >
                <FeatherIcon icon="EyeIcon" /> Raporu Görüntüle
              </b-button>
            </div>
          </div>
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BButton,
} from 'bootstrap-vue'
import StartupCard from '@/views/Dealers/Startup_applies/components/StartupCard.vue'

export default {
  name: 'StartupView',
  components: {
    BCard,
    BRow,
    BCol,
    BButton,
    StartupCard,
  },
  computed: {
    startup() {
      return this.$store.getters['dealerStartupApplies/getStartup']
    },
    dataList() {
      return this.$store.getters['dealerStartupApplies/startupReports']
    },
  },
  created() {
    this.getDataList()
  },
  methods: {
    getDataList() {
      this.$store.dispatch('dealerStartupApplies/getStartupReports', this.$route.params.id)
    },
  },
}
</script>
